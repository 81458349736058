import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
    FlatList,
    Modal,
    Pressable,
    SafeAreaView,
    Text,
    View,
    ViewProps,
    ViewStyle,
} from 'react-native';
import HttpClient from '../utils/HttpClient';
import { Reservations } from '../queries/Reservations';
import {
    BookingLinkStatus,
    PaginatorInfo,
    QueryReservationsOrderByColumn,
    Reservation,
    SortOrder,
} from '../gql/graphql';
import tw from '../utils/Tailwind';
import Header from '../components/layout/ConciergeHeader';
import ThreeDotIcon from '../components/icons/ThreeDotIcon';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import ColumnSortingIcon from '../components/icons/ColumnSortingIcon';
import PopUpMenu from '../components/layout/PopUpMenu.Concierge';
import * as Clipboard from 'expo-clipboard';
import FilterIconOutline from '../components/icons/FilterIconOutline';
import BasicButton from '../components/inputs/BasicButton';
import ChevronLeftIcon from '../components/icons/ChevronLeftIcon';
import Checkbox from '../components/inputs/Checkbox';
import { useIsFocused } from '@react-navigation/native';
import { format } from 'date-fns';
import CloseIcon from '../components/icons/CloseIcon';
import API from '../utils/API';

const BookingLinksScreen = ({ navigation }: NativeStackScreenProps<any>) => {
    const [bookings, setBookings] = useState<Reservation[]>([]);
    const [paginatorInfo, setPaginatorInfo] = useState<
        PaginatorInfo | undefined
    >();

    const isFocused = useIsFocused();

    const [reservationCancelModal, setReservationCancelModal] = useState({});

    const [sorting, setSorting] = useState({
        column: QueryReservationsOrderByColumn.CreatedAt,
        direction: SortOrder.Desc,
    });

    const [filters, setFilters] = useState<{ linkStatus: string[] }>({
        linkStatus: [],
    });

    const fetchReservations = (page: number = 1) => {
        HttpClient.graphqlQuery(Reservations, {
            order: sorting.direction,
            column: sorting.column,
            page,
            status: filters.linkStatus,
        }).then((data) => {
            setBookings(data.reservations.data);
            setPaginatorInfo(data.reservations.paginatorInfo);
            scrollFlatListToTop();
        });
    };

    useEffect(() => {
        fetchReservations(1);
    }, [isFocused, sorting, filters]);

    const TableRow = (props: ViewProps) => {
        return (
            <View
                {...props}
                style={[
                    tw`flex flex-row w-full justify-between items-center rounded-md bg-brand-white border-b border-brand-altGray px-4 py-3`,
                    props.style,
                ]}
            >
                {props.children}
            </View>
        );
    };

    const TableCell = ({
        children,
        percent,
        style,
    }: {
        children: ReactElement | ReactElement[];
        percent?: number;
        style?: ViewStyle | undefined;
    }) => {
        return (
            <View
                style={[
                    tw`px-1 py-1.5`,
                    percent ? tw`w-[${percent}%]` : tw``,
                    style,
                ]}
            >
                {children}
            </View>
        );
    };

    interface BookingLinkStatusPillProps extends ViewProps {
        linkStatus: BookingLinkStatus;
    }

    const BookingLinkStatusPill = (props: BookingLinkStatusPillProps) => {
        let color: string;

        switch (props.linkStatus) {
            case BookingLinkStatus.Cancelled:
                color = 'red';
                break;
            case BookingLinkStatus.Expired:
                color = 'pink';
                break;
            case BookingLinkStatus.Sent:
                color = 'yellow';
                break;
            case BookingLinkStatus.Confirmed:
                color = 'green';
                break;
            case BookingLinkStatus.Complete:
                    color = 'gray';
                    break;
            default:
                color = 'gray';
        }

        const statusLabel = props.linkStatus.valueOf();
        const firstLetter = statusLabel.charAt(0);
        const restOfWord = statusLabel.slice(1);

        const newLabel = firstLetter.toUpperCase() + restOfWord.toLowerCase();

        return (
            <View
                {...props}
                style={[
                    tw`flex items-center justify-center px-3 py-2 rounded-full bg-status-${color}-background`,
                    props.style,
                ]}
            >
                <Text style={tw`text-status-${color}-text`}>{newLabel}</Text>
            </View>
        );
    };

    const sortingAction = (column: QueryReservationsOrderByColumn) => {
        if (sorting.column === column) {
            setSorting((prev) => {
                return {
                    direction:
                        prev.direction === SortOrder.Asc
                            ? SortOrder.Desc
                            : SortOrder.Asc,
                    column: sorting.column,
                };
            });
            return;
        }

        setSorting((prev) => {
            return {
                direction: SortOrder.Desc,
                column: column,
            };
        });
    };

    const RenderReservationMenu = ({ reservation }) => {
        const [showMenu, setShowMenu] = useState(false);
        const [linkCopied, setLinkCopied] = useState(false);

        let reservationMenuColor: string;

        switch (reservation.booking_link_status) {
            case BookingLinkStatus.Cancelled:
                reservationMenuColor = 'red';
                break;
            case BookingLinkStatus.Expired:
                reservationMenuColor = 'pink';
                break;
            case BookingLinkStatus.Sent:
                reservationMenuColor = 'yellow';
                break;
            case BookingLinkStatus.Confirmed:
                reservationMenuColor = 'green';
                break;
            default:
                reservationMenuColor = 'gray';
        }

        const openIntercom = (message: String) => {
            window.Intercom('showNewMessage', message);
        };

        const MenuLayout = ({ children }) => {
            return (
                <View style={tw`flex-1 w-full`}>
                    <Pressable
                        onPress={() => {
                            setShowMenu((prev) => !prev);
                        }}
                        style={tw`flex flex-row items-center justify-center`}
                    >
                        <ThreeDotIcon />
                    </Pressable>
                    <PopUpMenu
                        show={showMenu}
                        dontShow={() => setShowMenu(false)}
                    >
                        {children}
                    </PopUpMenu>
                </View>
            );
        };

        const RenderModificaionsMenu = (reservation: Reservation) => {
            const canModify = () => {
                return (
                    <View style={tw`flex justify-between items-start min-w-40`}>
                        <Pressable
                            style={tw`flex py-4 px-2 text-left right-0 border-b border-brand-gray`}
                            onPress={() => {
                                Clipboard.setStringAsync(
                                    reservation.booking_link
                                );
                                setLinkCopied(true);
                            }}
                        >
                            <Text style={tw`bold`}>
                                {linkCopied
                                    ? 'Link Copied!'
                                    : 'Copy Booking Link'}
                            </Text>
                        </Pressable>
                        <Pressable
                            style={tw`flex py-4 px-2 text-left right-0`}
                            onPress={() => {
                                setReservationCancelModal(reservation);
                            }}
                        >
                            <Text style={tw`bold text-brand-red`}>
                                Cancel Booking
                            </Text>
                        </Pressable>
                    </View>
                );
            };

            const cantModify = () => {
                return (
                    <View style={tw`flex justify-between items-start min-w-40`}>
                        <Pressable
                            style={tw`flex py-4 px-2 text-left right-0 border-b border-brand-gray`}
                            onPress={() => {
                                Clipboard.setStringAsync(
                                    reservation.booking_link
                                );
                                setLinkCopied(true);
                            }}
                        >
                            <Text style={tw`bold`}>
                                {linkCopied
                                    ? 'Link Copied!'
                                    : 'Copy Booking Link'}
                            </Text>
                        </Pressable>
                        <Pressable
                            style={tw`flex py-4 px-2 text-left right-0 border-b border-brand-gray`}
                            onPress={() => {
                                openIntercom('');
                            }}
                        >
                            <Text style={tw`bold`}>Modify Booking</Text>
                        </Pressable>
                        <Pressable
                            style={tw`flex py-4 px-2 text-left right-0`}
                            onPress={() => {
                                setReservationCancelModal(reservation);
                            }}
                        >
                            <Text style={tw`bold text-brand-red`}>
                                Cancel Booking
                            </Text>
                        </Pressable>
                    </View>
                );
            };

            switch (reservation.booking_link_status) {
                case BookingLinkStatus.Sent:
                    return <MenuLayout>{canModify()}</MenuLayout>;
                case BookingLinkStatus.Confirmed:
                    return <MenuLayout>{cantModify()}</MenuLayout>;
                default:
                    return <></>;
            }
        };

        return RenderModificaionsMenu(reservation);
    };

    const tableColumnWidths = [15, 10, 15, 15, 22, 10, 8];

    function ListHeaderComponent() {
        const [showStatusFilter, setShowStatusFilter] = useState(false);
        const RenderStatusMenu = () => {
            const ToggleStatusFilter = ({ status }: { status: string }) => {
                let color: string;

                switch (status.toUpperCase()) {
                    case BookingLinkStatus.Cancelled:
                        color = 'red';
                        break;
                    case BookingLinkStatus.Expired:
                        color = 'pink';
                        break;
                    case BookingLinkStatus.Sent:
                        color = 'yellow';
                        break;
                    case BookingLinkStatus.Confirmed:
                        color = 'green';
                        break;
                    case BookingLinkStatus.Complete:
                        color = 'gray';
                        break;
                    default:
                        color = 'gray';
                }

                const onPress = () => {
                    setFilters((prev: { linkStatus: string[] }) => {
                        return {
                            linkStatus: prev.linkStatus.includes(status)
                                ? prev.linkStatus.filter((c) => c !== status)
                                : [...prev.linkStatus, status],
                        };
                    });
                };
                return (
                    <Checkbox
                        onChange={() => onPress()}
                        checked={filters.linkStatus.includes(status)}
                        textLeft={true}
                        boxStyle={tw`flex-row-reverse items-center justify-start pb-1 border-b border-brand-lightGray`}
                        style={tw`rounded border border-brand-black h-6 w-6`}
                        value={status}
                    >
                        <View style={tw`flex-1 items-start py-2 px-4`}>
                            <Text
                                style={tw`flex-1 text-brand-black capitalize bg-status-${color}-background px-4 py-2 rounded-full`}
                            >
                                {status === 'complete'
                                    ? 'Reservation Complete'
                                    : status}
                            </Text>
                        </View>
                    </Checkbox>
                );
            };

            return (
                <>
                    <BasicButton
                        onPress={() => {
                            setShowStatusFilter(true);
                        }}
                        style={tw`flex flex-row justify-start items-center gap-1 -ml-4`}
                    >
                        <Text style={tw`font-medium`}>Status</Text>{' '}
                        <FilterIconOutline />
                        {filters.linkStatus?.length > 0 && (
                            <View
                                style={tw`bg-brand-black rounded-full h-5 w-5 flex items-center justify-center -mt-5`}
                            >
                                <Text
                                    style={tw`text-brand-white p-0 m-0 text-sm font-bold`}
                                >
                                    {filters.linkStatus?.length}
                                </Text>
                            </View>
                        )}
                    </BasicButton>
                    <PopUpMenu
                        show={showStatusFilter}
                        dontShow={() => {
                            setShowStatusFilter(false);
                        }}
                    >
                        <View style={tw`w-55 py-4`}>
                            <Text style={tw`bold mb-3 `}>Filter status by</Text>
                            <ToggleStatusFilter status={'expired'} />
                            <ToggleStatusFilter status={'sent'} />
                            <ToggleStatusFilter status={'confirmed'} />
                            <ToggleStatusFilter status={'cancelled'} />
                            <ToggleStatusFilter status={'complete'} />
                        </View>
                    </PopUpMenu>
                </>
            );
        };

        return (
            <View
                style={[
                    tw`flex-row items-center justify-between bg-brand-bgGray pt-8`,
                ]}
            >
                <TableRow
                    style={tw`sm:w-full lg:width-limit-wide lg:px-0 mx-auto flex-row gap-2 bg-brand-white `}
                >
                    <TableCell percent={tableColumnWidths[0]}>
                        <Text style={tw`font-medium pl-4`}>Member Details</Text>
                    </TableCell>
                    <TableCell percent={tableColumnWidths[1]}>
                        <Pressable
                            style={tw`flex flex-row items-center gap-2`}
                            onPress={() => {
                                sortingAction(
                                    QueryReservationsOrderByColumn.TableMinimum
                                );
                            }}
                        >
                            <Text style={tw`font-medium`}>Table Min.</Text>
                            <ColumnSortingIcon />
                        </Pressable>
                    </TableCell>
                    <TableCell percent={tableColumnWidths[2]}>
                        <Pressable
                            style={tw`flex flex-row items-center gap-2`}
                            onPress={() => {
                                sortingAction(
                                    QueryReservationsOrderByColumn.ReservationAt
                                );
                            }}
                        >
                            <Text style={tw`font-medium`}>
                                Reservation Details
                            </Text>
                            <ColumnSortingIcon />
                        </Pressable>
                    </TableCell>
                    <TableCell percent={tableColumnWidths[3]}>
                        <Pressable
                            style={tw`flex flex-row items-center gap-2`}
                            onPress={() => {
                                sortingAction(
                                    QueryReservationsOrderByColumn.CreatedAt
                                );
                            }}
                        >
                            <Text style={tw`font-medium`}>Created At</Text>
                            <ColumnSortingIcon />
                        </Pressable>
                    </TableCell>
                    <TableCell percent={tableColumnWidths[4]}>
                        <Text style={tw`font-medium`}>Restaurants</Text>
                    </TableCell>
                    <TableCell percent={tableColumnWidths[5]} style={tw``}>
                        <RenderStatusMenu />
                    </TableCell>
                    <TableCell percent={tableColumnWidths[6]}>
                        <Text style={tw`font-medium`}>Actions</Text>
                    </TableCell>
                </TableRow>
            </View>
        );
    }

    const flatListRef = useRef<FlatList<Reservation>>(null);

    const scrollFlatListToTop = () => {
        flatListRef.current?.scrollToOffset({
            offset: 0,
            animated: true,
        });
    };

    const cancelBookingLink = (uuid: String) => {
        API.booking
            .cancel({
                uuid,
            })
            .then((response) => {
                setReservationCancelModal({});
                fetchReservations();
            });
    };

    const localFormatDate = (date: string) => {
        const tempDate = new Date(date.replace(/\s/g, 'T') + 'Z');

        return (format(tempDate, 'E LLL d, h:mmaaa'))
    };

    return (
        <SafeAreaView style={tw`flex-1 bg-brand-bgGray`}>
            <View style={tw`flex bg-brand-white`}>
                <Header
                    bottomChild={
                        <View
                            style={tw`w-full flex-row items-center justify-start mx-auto pt-8 pb-4`}
                        >
                            <View style={tw`lg:w-1/10 pr-4`}>
                                <View style={tw`flex-row justify-start`}>
                                    <Pressable
                                        onPress={() => {
                                            if (navigation.canGoBack())
                                                navigation.goBack();
                                            else navigation.navigate('Home');
                                        }}
                                        style={tw`py-2 px-4 rounded flex-row items-center justify-center border border-brand-black rounded-full`}
                                    >
                                        <View style={tw`pr-2`}>
                                            <ChevronLeftIcon />
                                        </View>
                                        <Text style={tw`label-xxsmall`}>
                                            {' '}
                                            Back
                                        </Text>
                                    </Pressable>
                                </View>
                            </View>
                            <View style={tw`flex-row items-center `}>
                                <Text
                                    numberOfLines={2}
                                    lineBreakMode='tail'
                                    style={tw`heading-1 bold`}
                                >
                                    All booking links
                                </Text>
                            </View>
                        </View>
                    }
                />
            </View>

            <FlatList
                data={bookings}
                ref={flatListRef}
                ListHeaderComponent={ListHeaderComponent()}
                ListHeaderComponentStyle={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 50,
                }}
                renderItem={({ item, index }) => {
                    return (
                        <TableRow
                            key={index}
                            style={tw`sm:w-full lg:width-limit-wide px-4 mx-auto my-2 flex-1 gap-2`}
                        >
                            <TableCell percent={tableColumnWidths[0]}>
                                <Text style={tw`font-medium`}>
                                    {item.user.first_name || item.user.last_name
                                        ? `${item.user.first_name} ${item.user.last_name}`
                                        : 'N/A'}
                                </Text>
                                <Text style={tw`text-gray-500`}>
                                    {item.user.phone_formatted}
                                </Text>
                            </TableCell>
                            <TableCell percent={tableColumnWidths[1]}>
                                <Text>{item.table_minimum__currency}</Text>
                            </TableCell>
                            <TableCell percent={tableColumnWidths[2]}>
                                <Text>
                                    {
                                        item.reservation_at_local__formatted
                                            .date_dow_short
                                    }{' '}
                                    {
                                        item.reservation_at_local__formatted
                                            .date_short
                                    }
                                    ,{' '}
                                    {item.reservation_at_local__formatted.time}
                                </Text>
                                <View style={tw`mt-1`}>
                                    <Text>
                                        {item.party_size} guests •{' '}
                                        {item.price_per_person__currency} pp
                                    </Text>
                                </View>
                            </TableCell>
                            <TableCell percent={tableColumnWidths[3]}>
                                <Text>
                                    {localFormatDate(item.created_at)}
                                </Text>
                            </TableCell>
                            <TableCell percent={tableColumnWidths[4]}>
                                <Text>
                                    {item.restaurant.name}{' '}
                                    <Text style={tw`text-gray-500`}>
                                        • {item.restaurant.area?.name}
                                    </Text>
                                </Text>
                            </TableCell>
                            <TableCell percent={tableColumnWidths[5]}>
                                <View style={tw`flex flex-row`}>
                                    <BookingLinkStatusPill
                                        linkStatus={item.booking_link_status}
                                    />
                                </View>
                            </TableCell>
                            <TableCell percent={tableColumnWidths[6]}>
                                <RenderReservationMenu reservation={item} />
                            </TableCell>
                        </TableRow>
                    );
                }}
                ListFooterComponent={() => {
                    if (!bookings.length) return null;
                    return (
                        <>
                            {!!paginatorInfo?.currentPage && (
                                <View
                                    style={tw`sm:w-full lg:width-limit-wide px-8 mx-auto my-2 gap-2 flex-row bg-brand-white mb-30 py-5 rounded-lg`}
                                >
                                    <TableCell percent={50}>
                                        <View>
                                            {paginatorInfo.currentPage > 1 && (
                                                <Pressable
                                                    onPress={() => {
                                                        fetchReservations(
                                                            paginatorInfo.currentPage -
                                                                1
                                                        );
                                                    }}
                                                >
                                                    <Text
                                                        style={tw`font-medium`}
                                                    >
                                                        ‹ Previous page
                                                    </Text>
                                                </Pressable>
                                            )}
                                        </View>
                                    </TableCell>
                                    <TableCell
                                        percent={50}
                                        style={tw`flex flex-row justify-end`}
                                    >
                                        <View>
                                            {paginatorInfo?.hasMorePages ===
                                                true && (
                                                <Pressable
                                                    onPress={() => {
                                                        fetchReservations(
                                                            paginatorInfo.currentPage +
                                                                1
                                                        );
                                                    }}
                                                >
                                                    <Text
                                                        style={tw`font-medium`}
                                                    >
                                                        Next page ›
                                                    </Text>
                                                </Pressable>
                                            )}
                                        </View>
                                    </TableCell>
                                </View>
                            )}
                        </>
                    );
                }}
            />
            {reservationCancelModal.uuid && (
                <View
                    style={tw`absolute w-screen h-screen bg-brand-black bg-opacity-70 z-20`}
                ></View>
            )}
            <Modal
                animationType='slide'
                transparent={true}
                visible={reservationCancelModal.uuid ? true : false}
                presentationStyle='overFullScreen'
            >
                <View style={tw`flex-1 relative px-4`}>
                    <View
                        style={tw`sm:w-full lg:max-w-125 mx-auto my-auto bg-brand-white rounded-xl shadow-xl h-80 absolute top-0 left-0 right-0 bottom-0`}
                    >
                        <View style={tw`border-b border-brand-lightGray`}>
                            <Text style={tw`heading-4 bolder text-center py-4`}>
                                {reservationCancelModal.booking_link_status ===
                                BookingLinkStatus.Sent
                                    ? 'Cancel booking link?'
                                    : 'Reservation cancelation not available'}
                            </Text>
                            <Pressable
                                onPress={() => setReservationCancelModal({})}
                                style={[
                                    tw`absolute top-2 right-2 p-4`,
                                    { outlineStyle: 'none' },
                                ]}
                            >
                                <CloseIcon />
                            </Pressable>
                        </View>

                        {reservationCancelModal.booking_link_status ===
                        BookingLinkStatus.Sent ? (
                            <View
                                style={tw`flex flex-col items-center justify-center`}
                            >
                                <Text style={tw`text-left mt-12 px-8`}>
                                    Are you sure you want to cancel this link?
                                </Text>
                                <Pressable
                                    onPress={() => {
                                        cancelBookingLink(
                                            reservationCancelModal.uuid
                                        );
                                    }}
                                    style={tw`px-4 py-2 border-2 border-brand-red rounded-full mt-12`}
                                >
                                    <Text style={tw`text-brand-red bold`}>
                                        Yes, cancel booking link
                                    </Text>
                                </Pressable>
                            </View>
                        ) : (
                            <View
                                style={tw`flex flex-col items-center justify-center`}
                            >
                                <Text style={tw`text-left mt-12 px-8`}>
                                    This reservation was confirmed by the user.
                                    Please instruct them to cancel the
                                    reservation via the booking link page, their
                                    confirmation email or via the Dorsia app.
                                </Text>
                                <Pressable
                                    onPress={() => {
                                        setReservationCancelModal({});
                                    }}
                                    style={tw`px-4 py-2 border-2 border-brand-black rounded-full mt-12`}
                                >
                                    <Text style={tw`bold`}>Close</Text>
                                </Pressable>
                            </View>
                        )}
                    </View>
                </View>
            </Modal>
        </SafeAreaView>
    );
};

export default BookingLinksScreen;

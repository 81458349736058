import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Pressable, SafeAreaView, ScrollView, Text, View } from 'react-native';
import { GetRestaurantQuery, Reservation, Restaurant } from '../gql/graphql';
import HttpClient from '../utils/HttpClient';
import { GetRestaurant } from '../queries/Restaurants';
import API from '../utils/API';
import { useSearchContext } from '../contexts/SearchContext';
import {
    Availability,
    AvailabilityOption,
} from '../classes/Types/Availability';
import tw from '../utils/Tailwind';
import BookOnBehalfForm from '../components/inputs/Booking/BookOnBehalfForm';
import Header from '../components/layout/ConciergeHeader';
import { useAuthContext } from '../contexts/AuthContext';
import CustomCalendar from '../components/inputs/CalendarSingle';
import Shimmer from '../components/loaders/Shimmer';
import RestaurantInventorySliderControl from '../components/items/RestaurantInventorySliderControl';
import ChevronLeftIcon from '../components/icons/ChevronLeftIcon';
import BasicTextInput from '../components/inputs/BasicTextInput';
import { PhoneInput } from '../components/inputs/PhoneInput';

const RestaurantScreen = ({ navigation, route }) => {
    const { filterData, setFilterData } = useSearchContext();
    const { currentUser } = useAuthContext();

    const [restaurant, setRestaurant] = useState<Restaurant | undefined>(
        undefined
    );

    const selectedTime = route?.params?.availability_time;
    const availability_date = route?.params?.availability_date;

    const [availability, setAvailability] = useState<Availability[]>([]);
    const [restaurantPageDate, setRestaurantPageDate] = useState(
        availability_date
            ? availability_date
            : filterData.start_date
    );
    const [initialTimeInventoryIndex, setInitialTimeInventoryIndex] = useState(
        route.params?.availability?.findIndex(
            (item: { uid: any }) => item?.uid === selectedTime?.uid
        )
    );

    const [disabledClick, setDisabledClick] = useState(false);
    const [time, setTime] = useState(selectedTime || null);
    const [nestedNubs, setNestedNubs] = useState(selectedTime?.options || []);
    const [chosenAvailabilityOption, setChosenAvailabilityOption] =
        useState<AvailabilityOption | null>(null);
    const [confirmedBooking, setConfirmedBooking] = useState<
        Reservation | undefined
    >();

    const [dataLoading, setDataLoading] = useState(false);
    const [timezone, setTimezone] = useState(false);

    const [bookingType, setBookingType] = useState<string>('link');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [validatedEmail, setValidatedEmail] = useState(false);
    const [phone, setPhone] = useState('');
    const [useCustomerCard, setUseCustomerCard] = useState(false);
    const [clearBooking, setClearBooking] = useState<boolean>(false);

    const lookupAvailability = () => {
        setDataLoading(true);
        setDisabledClick(true);
        setClearBooking(true);
        API.restaurant
            .availability({
                restaurant_id: route.params.id,
                party_size: filterData.party_size,
                start_date: restaurantPageDate,
                end_date: null,
            })
            .then((data) => {
                // console.log(data.availability);
                setAvailability(data.availability ?? []);
                if (data.availability === 0) {
                    setTime(null);
                    setChosenAvailabilityOption(null);
                    setDataLoading(false);
                }
            })
            .finally(() => {
                setDisabledClick(false);
                setTimeout(() => {
                    setDataLoading(false);
                }, 300);
            });
    };

    useEffect(() => {

        setChosenAvailabilityOption(null);

        setRestaurantPageDate(
            route.params.availability_date
                ? route.params.availability_date
                : filterData.start_date
        );

        setFilterData((prev) => {
            return {
                ...prev,
                start_date: restaurantPageDate,
                end_date: null,
            };
        });

        if (!route?.params?.id) {
            navigation.navigate('Home');
        }

        HttpClient.graphqlQuery(GetRestaurant, { id: route.params.id }).then(
            (data: GetRestaurantQuery) => {
                setRestaurant(data.restaurant ?? undefined);
            }
        );

        setTime(null);
        lookupAvailability();
        setConfirmedBooking(undefined);
        
        // if (!navigation) return;

        // navigation.addListener('beforeRemove', (e: any) => {
        //     setFilterData((prev) => {
        //         return {
        //             ...prev,
        //             start_date: restaurantPageDate,
        //             end_date: null,
        //         };
        //     });
        // });

        // return () => {
        //     navigation.removeListener('beforeRemove');
        // };
    }, [navigation, route]);

    useEffect(() => {
        setTimezone(restaurant?.timezone)
    }, [restaurant]);

    useEffect(() => {
        if (bookingType === 'link') {
            setUseCustomerCard(false);
        }
    }, [bookingType]);

    const handleCalendarDateChange = (date) => {
        setRestaurantPageDate(date);
        setDisabledClick(true);
        setClearBooking(true);
        setDataLoading(true);
        setChosenAvailabilityOption(null);

        setFilterData((prev) => {
            return {
                ...prev,
                start_date: date,
                end_date: null,
            };
        });

        API.restaurant
            .availability({
                restaurant_id: route.params.id,
                party_size: filterData.party_size,
                start_date: date,
                end_date: null,
            })
            .then((data) => {
                // console.log(data.availability);
                setAvailability(data.availability ?? []);
                if (availability.length === 0) {
                    setTime(null);
                    setChosenAvailabilityOption(null);
                }
            })
            .finally(() => {
                setDisabledClick(false);
                setDataLoading(false);
            });
    };

    useEffect(() => {
        if (!nestedNubs?.length) setChosenAvailabilityOption(null);
    }, [nestedNubs]);

    useEffect(() => {
        if (!availability?.length) return;

        handleSelectTimeSlot(availability[0]);
        setTimeout(() => {
            const matchingTimeIndex = availability?.findIndex(
                (item: { uid: any }) => item?.uid === availability[0]?.uid
            );
            setInitialTimeInventoryIndex(matchingTimeIndex);
        }, 200);
    }, [availability]);

    const handleSelectTimeSlot = (timeSlot: {
        datetime_utc: any;
        options: string | any[];
    }) => {
        if (!timeSlot) return;
        setTime(timeSlot);
        setNestedNubs(timeSlot?.options);
        setChosenAvailabilityOption(
            timeSlot?.options?.length ? timeSlot?.options[0] : null
        );
    };

    const handleSelectNestedNub = (nestedNub: any) => {
        // track(
        //   isRestaurantAnEvent(restaurant)
        //     ? 'Event Tickets Selected'
        //     : 'Nested Nub Selected',
        //   {
        //     ...analyticsProps,
        //     nestedNub: nestedNub,
        //   }
        // );
        setChosenAvailabilityOption(nestedNub);
    };

    const handleEmailValidation = (email: string) => {
        if (!email) {
            setValidatedEmail(false);
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setValidatedEmail(emailRegex.test(email));
    };

    const renderHeaderBottomChild = () => {
        return (
            <View
                style={tw`w-full flex-row items-start justify-center mx-auto py-6`}
            >
                <View style={tw`lg:w-1/10 pr-4 pt-2`}>
                    <View style={tw`flex-row justify-start`}>
                        <Pressable
                            onPress={() => {
                                setFilterData((prev) => {
                                    return {
                                        ...prev,
                                        start_date: restaurantPageDate,
                                        end_date: null,
                                    };
                                });
                                setConfirmedBooking(undefined);
                                if (navigation.canGoBack()) navigation.goBack();
                                else navigation.navigate('Home');
                            }}
                            style={tw`py-2 px-4 rounded flex-row items-center justify-center border border-brand-black rounded-full`}
                        >
                            <View style={tw`pr-2`}>
                                <ChevronLeftIcon />
                            </View>
                            <Text style={tw`label-xxsmall`}> Back</Text>
                        </Pressable>
                    </View>
                </View>
                <View style={tw`flex-1`}>
                    <Text
                        numberOfLines={2}
                        lineBreakMode='tail'
                        style={tw`heading-1 bold`}
                    >
                        {restaurant.name}
                    </Text>
                    <Text>{restaurant.address}</Text>
                </View>
            </View>
        );
    };

    if (!restaurant) {
        return <View />;
    }

    return (
        <SafeAreaView style={tw`flex-1 bg-brand-white `}>
            <Header
                topChild={null}
                bottomChild={renderHeaderBottomChild()}
                conciergeUser={currentUser}
            />
            <ScrollView style={tw`flex-1 w-full bg-brand-bgGray`}>
                {!confirmedBooking ? (
                    <Text
                        style={tw`width-limit-medium mx-auto text-left text-brand-black text-2xl font-bold mt-8`}
                    >
                        Select reservation details
                    </Text>
                ) : null}
                <View
                    style={tw`width-limit-medium mx-auto sm:flex lg:flex-row items-start justify-center gap-6 my-8`}
                >
                    {!confirmedBooking ? (
                        <View
                            style={tw`lg:w-1/2 flex flex-wrap items-start w-full`}
                        >
                            <View
                                style={tw`w-full max-w-full bg-brand-white rounded-2xl px-10 py-5`}
                            >
                                <View style={tw`${dataLoading ? 'opacity-50' : ''}`}>
                                    <CustomCalendar
                                        onSelect={(day) => {
                                            handleCalendarDateChange(
                                                day.dateString
                                            );
                                        }}
                                        selected={restaurantPageDate}
                                        loading={dataLoading}
                                    />
                                </View>
                            </View>
                            <View style={tw`w-full`}>
                                {dataLoading && (
                                    <View
                                        style={tw`flex-row items-center gap-3 mt-3 opacity-40 overflow-hidden max-w-full mt-10`}
                                    >
                                        <View
                                            style={tw`bg-gray-200 h-22 w-36 rounded-lg overflow-hidden`}
                                        >
                                            <Shimmer />
                                        </View>
                                        <View
                                            style={tw`bg-gray-200 h-22 w-36 rounded-lg overflow-hidden`}
                                        >
                                            <Shimmer />
                                        </View>
                                        <View
                                            style={tw`bg-gray-200 h-22 w-36 rounded-lg overflow-hidden`}
                                        >
                                            <Shimmer />
                                        </View>
                                        <View
                                            style={tw`bg-gray-200 h-22 w-36 rounded-lg overflow-hidden`}
                                        >
                                            <Shimmer />
                                        </View>
                                    </View>
                                )}
                                {availability.length > 0 && !dataLoading ? (
                                    <View style={tw`mt-1 mb-3 pb-2 -ml-1 p-4`}>
                                        <Text
                                            style={tw`font-sailec mb-1 heading-4 text-black bold `}
                                        >
                                            Time
                                        </Text>
                                        <View
                                            style={tw`relative flex w-full max-w-full`}
                                        >
                                            <RestaurantInventorySliderControl
                                                options={availability}
                                                restaurant={restaurant}
                                                type='inventory'
                                                onPress={handleSelectTimeSlot}
                                                timezone={timezone}
                                                initialIndex={
                                                    initialTimeInventoryIndex
                                                }
                                                selectedItem={time}
                                                restaurant={restaurant}
                                            />
                                        </View>

                                        {time !== null &&
                                            nestedNubs?.length > 0 && (
                                                <View style={tw`w-full flex`}>
                                                    <Text
                                                        style={tw`font-sailec heading-4 text-black mt-5 bold`}
                                                    >
                                                        Table
                                                    </Text>
                                                    <RestaurantInventorySliderControl
                                                        options={nestedNubs}
                                                        onPress={(
                                                            option: any
                                                        ) => {
                                                            handleSelectNestedNub(
                                                                option
                                                            );
                                                        }}
                                                        selectedItem={
                                                            chosenAvailabilityOption
                                                        }
                                                        timezone={timezone}
                                                        disabled={disabledClick}
                                                        // analyticsProps={analyticsProps}
                                                        analyticsComponentName='Restaurant: Tables'
                                                        type='tables'
                                                    />
                                                </View>
                                            )}
                                    </View>
                                ) : !dataLoading ? (
                                    <View
                                        style={tw`flex flex-row items-center justify-center mt-10`}
                                    >
                                        <Text
                                            style={tw`text-center label-small`}
                                        >
                                            No availability found for the
                                            selected date
                                        </Text>
                                    </View>
                                ) : null}
                            </View>
                        </View>
                    ) : null}

                    <View
                        style={tw`flex lg:w-1/2 bg-brand-white rounded-2xl p-8 w-full min-h-[400px]`}
                    >
                        {dataLoading && (
                            <ActivityIndicator
                                color={tw.color('brand-altPurple')}
                                style={tw`absolute top-0 bottom-0 left-0 right-0`}
                            />
                        )}
                        {chosenAvailabilityOption && !dataLoading && (
                            <View>
                                <Text style={tw`heading-3 bold`}>
                                    {
                                        chosenAvailabilityOption
                                            ?.datetime_local__formatted
                                            .date_dow_short
                                    }
                                    ,{' '}
                                    {
                                        chosenAvailabilityOption
                                            ?.datetime_local__formatted
                                            .date_long
                                    }
                                </Text>
                                <Text style={tw`heading-3 bold`}>
                                    {
                                        chosenAvailabilityOption
                                            ?.datetime_local__formatted.time
                                    }
                                </Text>
                                <Text style={tw`heading-3 bold`}>
                                    {chosenAvailabilityOption?.seats}{' '}
                                    {chosenAvailabilityOption?.seats !== 1
                                        ? 'guests'
                                        : 'guest'}
                                </Text>
                                <Text style={tw`heading-3 bold`}>
                                    {chosenAvailabilityOption?.type}
                                </Text>
                                <Text style={tw`heading-4 pb-1 pt-4`}>
                                    {
                                        chosenAvailabilityOption?.table_minimum__currency
                                    }{' '}
                                    total minimum
                                </Text>
                                <Text style={tw`body pb-1`}>
                                    {chosenAvailabilityOption?.price__currency}{' '}
                                    minimum per person
                                </Text>
                            </View>
                        )}

                        {!confirmedBooking && chosenAvailabilityOption && (
                            <>
                                <View
                                    style={tw`flex-row items-center justify-center pt-6 pb-4`}
                                >
                                    <Pressable
                                        style={tw`flex-1 items-center`}
                                        onPress={() => setBookingType('link')}
                                    >
                                        <View style={tw`flex`}>
                                            <Text
                                                style={tw`flex heading-4 bold text-center`}
                                            >
                                                Send{' '}
                                                <Text
                                                    style={tw`hidden lg:flex`}
                                                >
                                                    booking{' '}
                                                </Text>
                                                link
                                            </Text>
                                            <View
                                                style={tw`h-1 max-w-full rounded-t-lg mt-2 ${
                                                    bookingType === 'link'
                                                        ? 'bg-brand-altPurple'
                                                        : ''
                                                }`}
                                            />
                                        </View>
                                    </Pressable>
                                    <Pressable
                                        style={tw`flex-1 items-center`}
                                        onPress={() => setBookingType('full')}
                                    >
                                        <View style={tw`flex`}>
                                            <Text
                                                style={tw`flex heading-4 bold text-center`}
                                            >
                                                Full booking
                                            </Text>
                                            <View
                                                style={tw`h-1 w-full rounded-t-lg mt-2 ${
                                                    bookingType === 'full'
                                                        ? 'bg-brand-altPurple'
                                                        : ''
                                                }`}
                                            />
                                        </View>
                                    </Pressable>
                                </View>

                                {!useCustomerCard && (
                                    <View style={tw`pt-4`}>
                                        <View style={tw`pb-4`}>
                                            <BasicTextInput
                                                isReverseColor={true}
                                                style={tw`py-2`}
                                                textStyle={tw`text-white`}
                                                label={'Guest first name'}
                                                onChangeText={setFirstName}
                                                placeholder={'First name'}
                                                value={firstName}
                                                testID='first-name-input'
                                            />
                                        </View>

                                        <View style={tw`pb-4`}>
                                            <BasicTextInput
                                                isReverseColor={true}
                                                style={tw`py-2`}
                                                textStyle={tw`text-white`}
                                                label={'Guest last name'}
                                                onChangeText={setLastName}
                                                placeholder={'Last name'}
                                                value={lastName}
                                                testID='last-name-input'
                                            />
                                        </View>

                                        {bookingType === 'full' && (
                                            <View style={tw`pb-4`}>
                                                <BasicTextInput
                                                    isReverseColor={true}
                                                    style={tw`py-2 ${
                                                        !validatedEmail && email
                                                            ? 'border-brand-red'
                                                            : ''
                                                    }`}
                                                    textStyle={tw`text-white`}
                                                    label={'Email'}
                                                    onChangeText={(email) => {
                                                        setValidatedEmail(true);
                                                        setEmail(email);
                                                    }}
                                                    placeholder={'Email'}
                                                    value={email}
                                                    testID='email-input'
                                                    onBlur={(e) => {
                                                        handleEmailValidation(
                                                            e.nativeEvent.text
                                                        );
                                                    }}
                                                />
                                            </View>
                                        )}

                                        <View style={tw`pb-4`}>
                                            <PhoneInput
                                                style={tw`ml-2 p-4 w-full`}
                                                dropDownStyle={tw`label-small text-black`}
                                                textStyle={tw`text-black`}
                                                label={'Phone'}
                                                boxStyle={tw`rounded border border-brand-black py-0`}
                                                labelStyle={tw`text-black mb-2 `}
                                                onParse={(phone) => {
                                                    setPhone(phone);
                                                }}
                                                placeholder={'Phone'}
                                                resetPhone={() => setPhone('')}
                                                dark={false}
                                                testID='phone-input'
                                            />
                                        </View>
                                    </View>
                                )}
                            </>
                        )}

                        <View style={tw`pt-4 ${!chosenAvailabilityOption ? 'hidden' : ''}`}>
                            <BookOnBehalfForm
                                restaurant={restaurant}
                                availabilityOption={chosenAvailabilityOption}
                                firstName={firstName}
                                lastName={lastName}
                                phone={phone}
                                email={email}
                                validatedEmail={validatedEmail}
                                bookingType={bookingType}
                                onUseCustomerCardChange={setUseCustomerCard}
                                onBooking={(reservation: Reservation) => {
                                    setFirstName('');
                                    setLastName('');
                                    setPhone('');
                                    setEmail('');
                                    setConfirmedBooking(reservation);
                                }}
                            />
                        </View>
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

export default RestaurantScreen;
